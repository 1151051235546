import * as Sentry from '@sentry/vue'

import type { Plugin } from 'vue'

const sentryVuePlugin: Plugin = {
  install: (app) => {
    Sentry.init({
      enabled: import.meta.env.VITE_SENTRY_VUE_ENABLED === 'true',
      app,

      dsn: (import.meta.env.VITE_SENTRY_VUE_DSN as string | undefined) ?? '',
      tracesSampleRate: Number.parseFloat(
        (import.meta.env.VITE_SENTRY_VUE_TRACES_SAMPLE_RATE as string | undefined) ?? '0.0',
      ),

      logErrors: true,

      environment: (import.meta.env.VITE_SENTRY_ENVIRONMENT as string | undefined) ?? 'failed',

      replaysSessionSampleRate: Number.parseFloat(
        (import.meta.env.VITE_SENTRY_VUE_REPLAYS_SESSION_SAMPLE_RATE as string | undefined) ??
          '0.0',
      ),
      replaysOnErrorSampleRate: Number.parseFloat(
        (import.meta.env.VITE_SENTRY_VUE_REPLAYS_ON_ERROR_SAMPLE_RATE as string | undefined) ??
          '1.0',
      ),

      timeout: 500,
      trackComponents: true,

      integrations: [Sentry.replayIntegration(), Sentry.browserTracingIntegration()],
    })
  },
}

export default sentryVuePlugin
